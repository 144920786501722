import { Component, OnInit } from '@angular/core';
import { ArticleService } from '../../services/article.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { articleView } from '../../interfaces/article';
import { env } from '../../config/config';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-iframe-view',
    templateUrl: './iframe-view.component.html',
    styleUrl: './iframe-view.component.scss'
})
export class IframeViewComponent implements OnInit {
    articleView: articleView;
    articleCoverImageUrl: string;
    journal_id = '';
    category: any;
    preview_mode: any;
    article_link: any;
    loading: boolean = false;
    articleSummary: string;
    safeSummary: any;
    sharedUrl = '';
    siteUrl = '';
    embededLink = '';
    formattedLikeCount = '';
    showLikeCount = false;
    isArticleLiked = false;
    subscriptions: Subscription[]=[];
    constructor(
        private articleService: ArticleService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private router: Router,
    ) {
        this.articleView = {
            article_summary: '',
            art_file_path: '',
            article_cover_image: '',
            cover_alt_text: '',
            article_id: '',
            article_title: '',
            article_link: '',
            article_type: '',
            category_dochead: '',
            category_tags: [],
            content_type: [],
            surgical_speciality: [],
            category_heading: {
                id: '',
                title: ''
            },
            article_timeline: [
                {
                    year: '',
                    date: '',
                    month: '',
                    type: '',
                },
            ],
            authors: [
                {
                    affiliation: '',
                    caption: '',
                    corresp_author: '',
                    email: '',
                    fig: '',
                    name: '',
                    orcid: '',
                    twitter_username: ''
                },
            ],
            doi: '',
            e_issn: '',
            html_content: '',
            id: {
                $oid: '',
            },
            journal_display_name: '',
            journal_id: '',
            journal_title: '',
            published_date: '',
            publisher_name: '',
            year: '',
            view_count: 0,
            download_count: 0,
            supplementary_files: [],
            read_paper: '',
            abstract: '',
            like_count: 0,
            author_notes: ''
        };
    }

    ngOnInit() {
        this.journal_id = env.journalName;
        this.route.params.subscribe(() => {
            this.articleLoading();
        });

    }

    articleLoading() {
        this.loading = true;

        if (this.route.snapshot.params['category']) {
            this.category = this.route.snapshot.params['category'];
        }
        else {
            this.category = this.route.snapshot.params['subcategory'];

        }
        this.preview_mode = this.route.snapshot.params['preview_mode'] ? this.route.snapshot.params['preview_mode'] : '';

        this.article_link = this.route.snapshot.params['article-link'];
        const text = 'bjs article preview';
        const encodedText = window.btoa(text);

        this.sharedUrl = env.siteUrl + '/bjs-academy/' + this.category + '/' + this.article_link;

        this.embededLink = env.siteUrl + '/bjs-academy/' + this.category + '/' + this.article_link + '/embed-link/embed#?secret=' + encodedText;
        this.siteUrl = env.siteUrl;

        const queryParams = {
            journal_id: this.journal_id,
            category: this.category,
            article_link: this.article_link,
            preview_mode: this.preview_mode
        };
        this.articleService.getArticleViewData(queryParams).subscribe(result => {
            if (result.status === 204) {
                // Redirect to the page not found route
                this.router.navigate(['/page-not-found']);
            }
            else {
                setTimeout(() => {
                    this.articleView = result.data;
                    
                    //recreating html............
                    const utf8Decoder = new TextDecoder('utf-8');
                    const decodedText = utf8Decoder.decode(new Uint8Array(this.articleView.article_summary.split('').map((char: string) => char.charCodeAt(0))));
                    this.safeSummary = this.sanitizer.bypassSecurityTrustHtml(decodedText);
                    this.showLikeCount = this.articleView?.like_count > 0 ? true : false;
                    this.formattedLikeCount = this.formatLikeCount(this.articleView?.like_count);
                    this.checkIfArticleIsLiked(this.articleView?.article_id);
                    this.loading = false
                }, 3000);
            }
            // this.modifyingHtmlContent(this.articleView?.html_content); 
        })
    }

    setCoverImage(cover_image: string) {
        this.articleCoverImageUrl = env.articleCoverImage + this.journal_id + '/';
        if (cover_image) {
            this.articleCoverImageUrl =
                this.articleCoverImageUrl + this.articleView?.category_heading?.id + '/' + this.articleView?.article_id + '/' + cover_image;
        }
        else {
            this.articleCoverImageUrl = 'bjs_article_cover_image.png';
        }
        
        return this.articleCoverImageUrl;
    }

    formatLikeCount(count: number): string {
        if (count >= 1000) {
            const formattedCount = (count / 1000);
            return formattedCount % 1 === 0 ? `${formattedCount.toFixed(0)}k` : `${formattedCount.toFixed(1)}k`;
        }
        return count?.toString();
    }

    addOrRemoveArticleLike() {
        this.isArticleLiked = !this.isArticleLiked;
        const action = !this.isArticleLiked ? 'decrement' : 'increment';
        const queryParams = {
            journal_id: this.journal_id,
            category: this.category,
            article_id: this.articleView?.article_id,
            action: action

        };
        const likeUpdateSub = this.articleService.updateLikeCount(queryParams).subscribe(response => {
            this.formattedLikeCount = this.formatLikeCount(response?.like_count);
            this.showLikeCount = response?.like_count > 0 ? true : false;
        })
        this.subscriptions.push(likeUpdateSub);
        let articleLikeCount = JSON.parse(localStorage.getItem('articleLikeCount') || '[]');
        if (this.isArticleLiked) {
            articleLikeCount.push({ article_id: this.articleView?.article_id, article_liked: true });
        } else {
            articleLikeCount = articleLikeCount.filter((article: any) => article?.article_id !== this.articleView?.article_id);
        }
        localStorage.setItem('articleLikeCount', JSON.stringify(articleLikeCount));
    }

    checkIfArticleIsLiked(article_id: string) {
        if (typeof window !== 'undefined' && window.localStorage) {
            const articleLikeCount = JSON.parse(localStorage.getItem('articleLikeCount') || '[]');
            this.isArticleLiked = articleLikeCount.some((article: any) => article?.article_id === article_id && article.article_liked === true);
        } else {
            this.isArticleLiked = false;
        }
    }

}
