import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { env } from '../../config/config';
import { authors_data } from '../../interfaces/article';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-authors',
    standalone: true,
    templateUrl: './authors.component.html',
    styleUrl: './authors.component.scss',
    imports:[CommonModule]
})
export class AuthorsComponent implements AfterViewInit, OnInit {
    showAuthorData:boolean[] = [];
    @Input() authors: authors_data[] = [];
    @Input() article_path:string ='';
    @Input() pageloading:boolean;
    authorsLoadingArray=new Array(5).fill(0);
    authorImageUrl = '';


    ngAfterViewInit() {
        
    }
    ngOnInit() {
        
        this.authorImageUrl = env.apiUrl + 'storage/';
    }

    setAuthorData(author: authors_data) {
        let detail = '';
        let splitData:string[] = [];
        if (author.name) {
            splitData = author.name.split(/<br>(.+)/s).filter(Boolean);
        }
        
        if (splitData.length > 0) {
            detail =
                "<h5 class='author-name'>" +
                splitData[0] ;
        }
        if (author.twitter_username !== '' && author.twitter_username !== undefined && author.twitter_username) {
            detail = detail + ', '+author.twitter_username;
        }
        detail = detail +"</h5>";

        if (splitData[1]) {
            // const formattedData = splitData[1].replace(/\n/g, '<br>');
            detail += "<p class='author-content mb-0'>" + splitData[1] + "</p>";
        }
        
        if (author.affiliation) {
            // const formattedData = author.affiliation.replace(/\n/g, '<br>');
            detail += "<p class='author-content mb-0'>" + author.affiliation + "</p>"
        }
        return detail;
    }

    setAuthorDataHover(author: authors_data) {
        let detail = '';
        let splitData:string[] = [];
        if (author.name) {
            splitData = author.name.split(/<br>(.+)/s).filter(Boolean);
        }
        if (splitData.length > 0) {
            detail =
                "<h5 class='author-nam'>" +
                splitData[0] ;
        }
        if (author.twitter_username !== '' && author.twitter_username !== undefined && author.twitter_username) {
            detail = detail + ', ' + author.twitter_username;
        }
        detail = detail +"</h5>";
        if (splitData[1]) {
            // const formattedData = splitData[1].replace(/\n/g, '<br>');
            detail += "<p class='author-det me-3 ms-2'>" + splitData[1] + "</p>";
        }
        if (author.affiliation) {
            // const formattedData = author.affiliation.replace(/\n/g, '<br>');
            detail += "<p class='author-det me-3 ms-2'>" + author.affiliation + "</p>"
        }
        return detail;
    }
}
