<section *ngIf="!loading" id="article-view-page">
    <div class="container">
        <div class="row">
            <!-- Image section -->
            <div class="col-4 image">
                <a href="{{sharedUrl}}" target="_blank">
                    <img class="card-img-top" tabindex="0" src={{setCoverImage(articleView?.article_cover_image)}}
                        alt="cover image">
                </a>

                <!-- <a href="{{siteUrl}}" target="_blank">
                <img id="logo" alt="" width="200" height="55" class="logo-image" tabindex="0" ng-reflect-router-link="/" src="bjs_logo.png" style="bottom:-2px; transition: height 0.3s ease;">
            </a> -->
            </div>
            <!-- Content section -->
            <div class="col-8 pe-5">
                <a href="{{sharedUrl}}" target="_blank" class="iframe-title-sec">
                    <h1 class="iframe-title" [innerHtml]="articleView.article_title"></h1>
                </a>
                <!--------------- abstract ---------------------->
                <div class="content-data">
                    <div *ngIf="articleView.abstract" id="article-content" class="iframe-content"
                        [innerHtml]="articleView.abstract">
                    </div>
                    <!---------------article content section----------->
                    <div id="article-content" class="iframe-content" [innerHtml]="safeSummary">
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <a href="{{siteUrl}}" target="_blank">
                    <img id="logo" alt="" width="200" height="55" class="logo-image" tabindex="0"
                        ng-reflect-router-link="/" src="bjs_logo.png"
                        style="bottom:-2px; transition: height 0.3s ease;">
                </a>
            </div>
            <div class="col-8">
                <!-- <div class=""> -->
                <div class="row">
                    <div class="col-8 share-button d-flex">
                        <span class="pub-date"><fa-icon [icon]="['fas','calendar-days']" class="icon-date"></fa-icon>{{
                            articleView?.published_date }}</span>
                    </div>
                    <div class="col-4 share-button d-flex justify-content-end">
                        <div class="dropdown">
                            <span class="dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <fa-icon [icon]="['fas', 'share-nodes']" class="share-icon"></fa-icon>
                            </span>
                            <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                <li><span class="dropdown-item">
                                        <share-button button="copy" text="Copy Link" showText
                                            [url]="sharedUrl"></share-button>
                                    </span>
                                </li>
                            </ul>

                        </div>
                        <div class="like-button">
                            <div class="badge-container">
                                <fa-icon [icon]="['far', 'thumbs-up']" class="share-icon" *ngIf="!isArticleLiked"
                                    (click)="addOrRemoveArticleLike()"></fa-icon>
                                <fa-icon [icon]="['fas', 'thumbs-up']" class="share-icon" *ngIf="isArticleLiked"
                                    (click)="addOrRemoveArticleLike()"></fa-icon>
                                <div class="badge">
                                    <span *ngIf="showLikeCount">({{ formattedLikeCount }})</span>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
</section>