import { Component, OnInit, OnDestroy, ViewChild, inject, ElementRef, HostListener, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { ArticleService } from '../../services/article.service';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { SearchContextService } from '../../services/search-context-service';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import {env} from '../../config/config'
@Component({
  selector: 'app-article-listing',
  templateUrl: './article-listing.component.html',
  styleUrls: ['./article-listing.component.scss']
})
export class ArticleListingComponent implements OnInit, OnDestroy {
  @ViewChild('maindiv') maindivElement!: ElementRef<HTMLDivElement>;  // Ensure it's non-null assertion

  load_filter = false;
  subscriptions: Subscription[] = [];
  private _length = 21;
  private _start = 0;
  journalid: string = '';
  articles: any[] = [];
  category: string = '';
  filteredArticles: any[] = [];
  currentPage: number = 1;
  // itemsPerPage: number = 10;
  totalPages: number = 1;
  startArticle: number;
  categories: any;
  categoryTitles: any[];
  categoryTitle: any[];
  CategoryarrayForListing: any;
  allArticles: boolean;
  listLoaderarray = new Array(8).fill(0);
  pageloading = true;
  adspageloading=true;
  total_count: any;
  filter_total_count:any;
  bannerpageloading=true;
  extraCategory:string[]=[];
  pageLoadingCat=true;
  isSticky: boolean = false;
  screeWidth=0;
  private readonly searchContextService = inject(SearchContextService);

  get surgical_speciality(){
    return this.searchContextService.selectedSpecialities();
  }

  get content_type(){
    return this.searchContextService.selectedContentType();
  }

  get searchValue(){
    return this.searchContextService.searchContent();
  }

   // Getter for start
   get start(): number {
    return (this.currentPage - 1) * this._length;
  }

  // Getter for length
  get length(): number {
    return this._length;
  }
  get surgical_speciality_count(): number {
    const specialities = this.searchContextService.selectedSpecialities();
    return Array.isArray(specialities) ? specialities.length : 0;
  }
  get content_type_count(): number {
    const content = this.searchContextService.selectedContentType();
    return Array.isArray(content) ? content.length : 0;
  }


  constructor(
    private articleService: ArticleService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any,
    private meta: Meta, private titleService: Title,
    
    
  ) { 
 if (isPlatformBrowser(this.platformId)) {
      this.screeWidth = window.innerWidth;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
     
      this.screeWidth = window.innerWidth;
      
        this.restroreSearchBar();
    }

  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
    // Dynamically calculate the navbar's height
    const navbar = document.querySelector('.navbar') as HTMLElement;  // Cast Element to HTMLElement
    const navbarHeight = navbar ? navbar.offsetHeight : 100;  // Fallback to 100px if navbar is not found
    const threshold=this.category!=='all-articles'?300:50;
    
    this.isSticky = scrollTop > threshold ; // Adjust the scroll position for sticky behavior
    if (this.isSticky && this.maindivElement  && this.screeWidth > 576) {
      // Sticky behavior: Set 'fixed' position and remove 'bottom' style
      this.renderer.setStyle(this.maindivElement.nativeElement, 'position', 'fixed');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'top', '-26px');
      // this.renderer.setStyle(this.maindivElement.nativeElement, 'z-index', '1');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'bottom','0px'); // Remove 'bottom' when sticky
      this.renderer.setStyle(this.maindivElement.nativeElement, 'height','fit-content'); 
      // this.renderer.setStyle(this.maindivElement.nativeElement, 'margin-left','-1rem'); 
      this.renderer.setStyle(this.maindivElement.nativeElement, 'width',this.screeWidth>=1200?'90%':'100%'); 
      this.renderer.setStyle(this.maindivElement.nativeElement, 'border-radius', '0px');

    } else if (!this.isSticky && this.maindivElement  && this.screeWidth > 576) {
      // Restore to default behavior: Set 'relative' position and restore 'bottom' style
    this.restroreSearchBar()
    }
    
  }
  restroreSearchBar(){
    if(this.maindivElement.nativeElement){
    // Restore to default behavior: Set 'relative' position and restore 'bottom' style
    this.renderer.setStyle(this.maindivElement.nativeElement, 'position', 'relative');
    this.renderer.setStyle(this.maindivElement.nativeElement, 'margin-left', '5rem');
    this.renderer.setStyle(this.maindivElement.nativeElement, 'margin-right', '5rem');
     this.renderer.setStyle(this.maindivElement.nativeElement, 'bottom', '100px');  // Restore 'bottom'
    // this.renderer.removeStyle(this.maindivElement.nativeElement, 'z-index');
    this.renderer.removeStyle(this.maindivElement.nativeElement, 'height');
    this.renderer.removeStyle(this.maindivElement.nativeElement, 'top');  // Remove 'top' when not sticky\
    this.renderer.setStyle(this.maindivElement.nativeElement, 'border-radius', '15px');
    this.renderer.removeStyle(this.maindivElement.nativeElement, 'width');
    }
  }
  ngOnInit() {
    //this.resetSearchContext();

    this.journalid = this.sharedService.getJournalId();
    this.route.params.subscribe(params => {
      this.load_filter = false;
    
    if(params['subcategory']){
      this.category = params['subcategory'];
      this.allArticles=false;
    }
    else if(params['category']){
      this.category = params['category'];
    }
    if( this.category ==="all-articles"){
      this.allArticles=true;
      //this.category='all-articles';
      this.getCategories();
    }
    else{
      this.allArticles=false;
    }
  //  this.getCategories();
    this.loadArticlesByCategory(this.category);
    // this.loadArticlesByCategory(this.category);
      this.currentPage = params['page'] ? +params['page'] : 1; // Default to page 1 if not specified

      if (!params['page']) {
        // If the page parameter is missing, redirect to page 1
        this.router.navigate([`/bjs-academy/${this.category}/page/1`]);
      } 

      // if (!params['page']) {
      //   // If the page parameter is missing, redirect to page 1
      //   this.router.navigate([`/academy/${this.category}/page/1`]);
      // } else {
      //   this.loadArticlesByCategory(this.category);
      // }

      setTimeout(() => {
        this.adspageloading=false;
        this.bannerpageloading=false;
      }, 500);

    });
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['speciality']) {
        const filterValue = queryParams['speciality'].split(',').map((filter: string) => filter.trim());
        this.searchContextService.setSelectedSpecialities(filterValue);
        const specialityCount = this.surgical_speciality_count;
        const contentTypeCount = this.content_type_count;
        this.filter_total_count = specialityCount + contentTypeCount;
      }
      
      if (queryParams['contentType']) {
        const contentType = queryParams['contentType'].split(',').map((filter: string) => filter.trim());
        this.searchContextService.setSelectedContentType(contentType);
        const specialityCount = this.surgical_speciality_count;
        const contentTypeCount = this.content_type_count;
  
        this.filter_total_count = specialityCount + contentTypeCount;
      }
    
     if(this.journalid)
     {
      this.filterDataApi(this.journalid);

     }
    });
    this.setMetaTags()
    
    
 
  }

  loadArticlesByCategory(category: string): void {
    this.startArticle = this.length * (this.currentPage - 1);
    if(this.journalid){
      this.filterDataApi(this.journalid);
    }
  }

  onFilterChange(filters: { surgical_speciality: string[], content_type: string[] }) {
  
    this.currentPage = 1;
    // this.router.navigate([`/academy/${this.category}/page/${this.currentPage}`]);
    this.filterDataApi(this.journalid)
    if (this.surgical_speciality_count=== 0 && this.content_type_count === 0) {
      // Clear filter count
      this.filter_total_count = 0;
    } else {
      this.filterDataApi(this.journalid);
      const specialityCount = this.surgical_speciality_count;
      const contentTypeCount = this.content_type_count;
  
      this.filter_total_count = specialityCount + contentTypeCount;
    
  
    }

  }
 
  private resetSearchContext(): void {
    this.searchContextService.setSelectedSpecialities([]);
    this.searchContextService.setSelectedContentType([]);
    this.searchContextService.setSearchContent(undefined);

}

  // onPageChange(page: number): void {
  
  //   if (page < 1 || page > this.totalPages) {
  //     return;
  //   }
  //   this.currentPage = page;
  //   // this.resetSearchContext();
  //    this.router.navigate([`/bjs-academy/${this.category}/page/${this.currentPage}`]);
    
  //   // this.start = (this.currentPage - 1) * this.length;
  //   this._start = (this.currentPage - 1) * this.length;



  //   if (this.currentPage > this.totalPages) {
  //     this.currentPage = this.totalPages; 
  //   } else {
  //     this.currentPage = page;
  //   }

  //   const content_type = this.searchContextService.selectedContentType();
  //   const surgical_speciality = this.searchContextService.selectedSpecialities();
  //   const searchValue = this.searchContextService.searchContent()

  //   const params: any = {
  //     length: this.length,
  //     start: this.start,
  //     category: this.category,
  //     content_type: JSON.stringify(content_type),
  //     surgical_speciality: JSON.stringify(surgical_speciality),
  //     searchValue:searchValue
  //   };
  // console.log(params)
  //   this.filterDataApi(this.journalid)
  // }
  onPageChange(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return;
    }
  
    this.currentPage = page;
    this._start = (this.currentPage - 1) * this.length;
  
    const content_type = this.searchContextService.selectedContentType();
    const surgical_speciality = this.searchContextService.selectedSpecialities();
    const searchValue = this.searchContextService.searchContent();
  
    const params: any = {
      length: this.length,
      start: this.start,
      category: this.category,
      content_type: JSON.stringify(content_type),
      surgical_speciality: JSON.stringify(surgical_speciality),
      searchValue: searchValue
    };
  

  
    // Update the URL with query parameters when navigating to a new page
    this.router.navigate(
      [`/bjs-academy/${this.category}/page/${this.currentPage}`],
      {
        queryParams: {
          search: searchValue || '',
          speciality: surgical_speciality.join(','),
          contentType: content_type.join(',')
        }
      }
    );
  
    this.filterDataApi(this.journalid);
  }
  
  updateFilteredArticles() {
    const startIndex = (this.currentPage - 1) * this.length;
    const endIndex = startIndex + this.length;
    this.filteredArticles = this.filteredArticles.slice(startIndex, Math.min(endIndex, this.filteredArticles.length));
  }

  // loadArticles(searchValue: string = ''): void {

  //   this.articleService.getArticles(searchValue, this.category).subscribe(
  //     (data: any) => {
  //       this.filteredArticles = data.data;
  //       this.pageloading=false;
  //     },
  //     (error) => {

  //     }
  //   );
  // }

  handleSearch(search_value: string) {
    this.searchContextService.setSearchContent(search_value)

    const content_type = this.searchContextService.selectedContentType();
    const surgical_speciality = this.searchContextService.selectedSpecialities();
    const searchValue = this.searchContextService.searchContent()

    const params: any = {
      length: this.length,
      start: this.start,
      category: this.category,
      content_type: JSON.stringify(content_type),
      surgical_speciality: JSON.stringify(surgical_speciality),
      searchValue:searchValue
    };

    if (this.journalid) {
      this.filterDataApi(this.journalid)
    // this.subscriptions.push(articleSub);
    }
  }

  filterDataApi(journalid: string): void {

    const content_type = this.searchContextService.selectedContentType();
    const surgical_speciality = this.searchContextService.selectedSpecialities();
    const searchValue = this.searchContextService.searchContent()

    const params: any = {
      length: this.length,
      start: this.start,
      category: this.category,
      content_type: JSON.stringify(content_type),
      surgical_speciality: JSON.stringify(surgical_speciality),
      searchValue:searchValue
    };
        if(params){
    const articleSub = this.articleService.getArticleList(journalid, params).subscribe(result => {
      this.articles = result.data;
      this.total_count=result.total_count
      this.filteredArticles = [...this.articles];
      this.pageloading = false;
      
      // this.totalPages = Math.ceil(this.filteredArticles.length / this.length);
      this.totalPages = Math.ceil(result.total_count / this.length);
      
  })
}
  }
  getCategories(): void {
    this.articleService.getAllCategories().subscribe((response: any) => {
      // Assuming "academy" is the identifier for the main "Academy" menu
      const academyMenu = response.find((menu: any) => menu.identifier === 'bjs-academy');
  
      if (academyMenu) {
        // Filter out "Scientific Surgery" submenu
        const filteredSubMenus = academyMenu.subMenus.filter((subMenu: any) => subMenu.identifier !== 'scientific-surgery');
        // Include "Scientific Surgery" submenus
        const scientificSurgeryMenu = academyMenu.subMenus.find((subMenu: any) => subMenu.identifier === 'scientific-surgery');
        if (scientificSurgeryMenu) {
          filteredSubMenus.push(...scientificSurgeryMenu.subMenus);
          this.categories = filteredSubMenus;
        }
        // Update categories and categoryTitles
        this.categories = filteredSubMenus;
        this.categoryTitles = ['All', ...this.categories.map((subMenu: any) => {
          return subMenu.menu_name;       // Return the menu_name for the map
        })];
        
       const filterMainCategories=this.categories.filter((subMenu: { parent: string; }) => subMenu.parent !== 'scientific-surgery')
        this.categoryTitles=['All',...filterMainCategories.map((subMenu: any) => {
          return subMenu.menu_name;       // Return the menu_name for the map
        })];
        const filterScientificSurgey=this.categories.filter((subMenu: { parent: string; }) => subMenu.parent === 'scientific-surgery')
        this.extraCategory=[...filterScientificSurgey.map((subMenu: any) => {
          return subMenu.menu_name;       // Return the menu_name for the map
        })];
        
        this.pageLoadingCat=false;
       // this.setCategoryTitle();
      }

    });
    
  }
  handleCategory(event:any){
    
this.category=event; 
// this.searchContextService.setSearchContent(this.searchValue)

// const content_type = this.searchContextService.selectedContentType();
// const surgical_speciality = this.searchContextService.selectedSpecialities();
const searchValue = this.searchContextService.searchContent()

// const params: any = {
//   length: this.length,
//   start: this.start,
//   category: this.category,
//   content_type: JSON.stringify(content_type),
//   surgical_speciality: JSON.stringify(surgical_speciality),
//   searchValue:searchValue
// };

if (this.journalid) {
  this.filterDataApi(this.journalid)
// this.subscriptions.push(articleSub);
}
this.filterDataApi(this.journalid)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      if (!x.closed) {
        x.unsubscribe();
      }
    });
    this.destroyMetaTagsforSEONew()
  }
  goto(url: string) {
    this.router.navigate([url])
  }
  setMetaTags() {
    let metaTagData={
     title:'',
     description:'',
     url:''

    };
  this.route.paramMap.subscribe(params => {
    if(this.category=='continuing-surgical-education'){
      metaTagData={
        title:'Surgical Education - Surgeon Research & Articles - BJS',
        description:'Continuing Surgical Education provides established surgeons with a range of learning & research material in the form of education seminars, written digests & videos.',
        url:env.siteUrl+'/bjs-academy/continuing-surgical-education/page/1'
      }
    }
    else if(this.category=='young-bjs'){
      metaTagData={
        title:'Young BJS - Trainee Surgeon News - Surgical Trainees',
        description:'Young BJS is designed by & for trainee surgeons. It will enable surgical trainees to expand on core surgeon learning by reading & critiquing surgeon research as well as designing surgical projects.',
        url:env.siteUrl+'/bjs-academy/young-bjs/page/1'
       };
    }
	else if(this.category=='cutting-edge-blog'){
		metaTagData={
			title:'Cutting Edge Blog - Surgery Articles & Information - BJS Academy',
			description:'The Cutting Edge blog adds value to the existing BJS Society journals & allows visitors to voice opinion & comment on the latest material.',
			url:env.siteUrl+'/bjs-academy/cutting-edge-blog/page/1'
		   };
		
	}
	else if (this.category=='surgical-news'){
		metaTagData={
			title:'Surgical News - Latest Surgeon Articles',
			description:'Find out the latest surgical news & explore topics relevant to both surgeons & people without medical training.',
			url:env.siteUrl+'/bjs-academy/surgical-news/page/1'
		   };
	}
	else if(this.category=='randomized-clinical-trials'){
		metaTagData={
			title:'Surgical Clinical Trials - BJS Academy',
			description:'Our randomized surgical clinical trials section is produced following a review of the content of the best surgical journals published in English worldwide.',
			url:env.siteUrl+'/bjs-academy/scientific-surgery/randomized-clinical-trials/page/1'
		   };
	}
	else if(this.category=='surgical-science'){
		metaTagData={
			title:'Surgical science - BJS Academy',
			description:"Read our page titled ' Surgical science '.",
			url:env.siteUrl+'/bjs-academy/scientific-surgery/surgical-science/page/1'
		   };
	}
	else if(this.category=='social-media'){
		metaTagData={
			title:'Social Media - BJS Academy',
			description:'View links to the Social Media accounts including Twitter (BJS & BJS Open), LinkedIn, Instagram & Youtube for the BJS Academy.',
			url:env.siteUrl+'/bjs-academy/scientific-surgery/social-media/page/1'
		   };
	}
    else if(this.category=='surgery-for-all'){
		metaTagData={
			title:'Surgery for all - BJS Academy',
			description:"Read our page titled ' Surgery for all '.",
			url:env.siteUrl+'/bjs-academy/scientific-surgery/surgery-for-all/page/1'
		};
  
	}
    this.titleService.setTitle(metaTagData.title); // Set page title
    
    
    // Set Open Graph meta tags
    this.meta.updateTag({ property: 'og:title', content:   metaTagData.title});
    // this.meta.updateTag({ property: 'og:image', content: 'https://example.com/path/to/image.jpg' });
    this.meta.updateTag({ property: 'og:description', content: metaTagData.description });
    this.meta.updateTag({ property: 'og:url', content: metaTagData.url });
  });
   
  }
  public destroyMetaTagsforSEONew() {
	this.titleService.setTitle('BJS Academy');
    this.meta.updateTag(
        { property: 'og:url', content: env.siteUrl },
    );
    this.meta.updateTag(
        { property: 'og:title', content: "BJS Academy" },
    );
    this.meta.updateTag(
        { property: 'og:description', content: "BJS Academy publish the foremost journal for surgeons as well as offering surgeon education resources & courses." },
    );
    this.meta.updateTag(
        { property: 'og:image', content: env.siteUrl+'/bjs_logo.png' },
    );
    
   
	}
  
}


//Api for filtering



  // onFilterChange(filters: { surgical_speciality: string[], content_type: string[] }) {
  //   this.filteredArticles = this.articles.filter(article => {
  //     const articleSpecialities = article.surgical_speciality || [];
  //     const articleContentTypes = article.content_type || [];

  //     const matchesSurgicalSpeciality = filters.surgical_speciality.length === 0 || filters.surgical_speciality.some(speciality => {
  //       return articleSpecialities.includes(speciality);
  //     });
  //     const matchesContentType = filters.content_type.length === 0 || filters.content_type.some(type => articleContentTypes.includes(type));

  //     return matchesSurgicalSpeciality && matchesContentType;
  //   });
  //   this.totalPages = Math.ceil(this.filteredArticles.length / this.itemsPerPage);
  //   this.currentPage = 1;
  //   this.updateFilteredArticles();
  // }
  
  // onPageChange1(page: number) {
  //   this.route.params.subscribe(params => {
  //     this.category = params['category'];
  //     this.loadArticlesByCategory(this.category);
  //     this.load_filter = false;
  //   });
  //   this.currentPage = page;
  //   this.router.navigate([`/academy/${this.category}/page/${this.currentPage}`]);

  //   this.startArticle = this.length * (this.currentPage - 1);
  //   const params: any = {
  //       length: this.length ,
  //       start: this.startArticle,
  //       category:  this.category,
      
  //   };
  //   const articleSub = this.articleService.getArticleList(this.journalid, params).subscribe(result => {
  //     this.articles = result.data;
  //     this.filteredArticles = [...this.articles];
  //     this.totalPages = Math.ceil(this.filteredArticles.length / this.length);
  //     this.updateFilteredArticles();
  // }, error => {
   
  // });

  //   this.currentPage = page;
  //   this.updateFilteredArticles();
    
  // }



  //   handleSearch(searchValue: string) {
//     const params: any = {
//       length: this.length,
//       start: this.start,
//       category: this.category,
      

//     };
  
//     if(this.journalid){
//     if (searchValue.trim() === '') {
//       const articleSub = this.articleService.getArticleList(this.journalid, params).subscribe(result => {
//         this.articles = result.data; 
      
//         this.filteredArticles = [...this.articles];
//         this.totalPages = Math.ceil(this.filteredArticles.length / this.length);
//         this.updateFilteredArticles();
//       }, error => {
//       });
  
//       this.subscriptions.push(articleSub);
//     } else {
//       this.articleService.getArticles(searchValue, this.category).subscribe(
//         (data: any) => {
//           this.filteredArticles = data.data;
       
//         },
//         (error) => {
         
//         }
//       );
//     }
//   }
//   }